import React from 'react'


export default function ContactPage(){
    return(
        <>
        <div>
            <h1>Contact Page</h1>
        </div>
        </>
    )
}