import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Splash2 from "./Splash_2";


// const useStyles = makeStyles((theme) => ({


// }));

export function MainSplash() {
	// const classes = useStyles();

	return (
		<>
			<Splash2 />
		</>
	);
}
